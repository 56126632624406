@import "variables.scss";

.is-bold {
    font-weight: bold;
}

.is-medium {
    font-weight: 500;
}

.is-light {
    font-weight: 300;
}

.has-side-padding {
    padding-left: 10px;
    padding-right: 10px;
    padding-left: 2rem;
    padding-right: 2rem;
}

.column,
.container {
    &.is-blue {
        background-color: $blue;
    }

    &.is-light-blue {
        background-color: $light-blue;
    }
}

// PET icon
.PET_icon_svg__cls-2{
    stroke-miterlimit: 10;
    fill: none;
    stroke: #010101;
    stroke-width: 4.09px;
}

@media screen and (min-width: 1024px) {
    .has-side-padding {
        padding-left: 10px;
        padding-right: 10px;
    }
}

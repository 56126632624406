@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,700&display=swap');

// Colors
$yellow: #FFCD00;
$blue: #B8DDE1;
$light-blue: #DCEEEF;
$gray: #F1F1F1;
$footer: #E5E5E5;

// Global variables
$link: $yellow;
$primary: $yellow;
$primary-invert: #000000;
$body-color: #000000;
$family-sans-serif: "Poppins", sans-serif;

// Navbar variables
$navbar-item-hover-background-color: none;

// Section variables
$section-padding-medium: 6rem 1.5rem;

// Button variables
$button-border-width: 0px;
$button-padding-vertical: calc(0.375em - #{$button-border-width});
$button-padding-horizontal: 2.75em;
$button-focus-box-shadow-size: 0;

@import "variables.scss";
@import "helpers.scss";
@import "~bulma/bulma.sass";

h1, .h1 {
    font-size: 20pt;

    & + p {
        margin-bottom: 0.6rem;
    }
}

h2, .h2 {
    font-size: 16pt;

    & + p {
        margin-top: 0.8rem;
    }
}

h1,
h2 {
    line-height: 1.2;
}

p,
h3,
h4 {
    font-size: 10pt;
}

.section {
    padding: 1.5rem 0;
}

.button {
    height: 50px;
    border-radius: 20px;
    font-weight: bold;
    font-size: 10pt;
}

@media screen and (min-width: 1024px) {
    h1, .h1 {
        font-size: 38pt;

        & + p {
            margin-top: 1.8rem;
        }
    }

    h2, .h2 {
        font-size: 25pt;

        & + p {
            margin-top: 0.8rem;
        }
    }

    h1,
    h2 {
        line-height: 1.2;
    }

    p,
    h3,
    h4 {
        font-size: 12pt;
    }

    .button {
        height: 50px;
        border-radius: 20px;
        font-weight: bold;
    }

    .has-side-padding {
        padding-left: 10px;
        padding-right: 10px;
    }
}
